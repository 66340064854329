import React, { useRef, useEffect, useState } from "react";
import { Formik, FormikErrors, FormikProps, getIn } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import {
  affaireInterface,
  editAffaire,
  getOneAffaire,
} from "../../redux/slices/affaireSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";

import * as yup from "yup";
import GenreAffaireModal from "../../components/Modals/GenreAffaireModal";
import GenreAffaireCreateModal from "../../components/CreateModals/GenreAffaireCreateModal";
import AddIcon from "@mui/icons-material/Add";
import Spinner from "../../components/Spinner";
import AffaireDetail from "./AffaireDetail";
import ClientModal from "../../components/Modals/ClientModal";
import ClientCreateModal from "../../components/CreateModals/ClientCreateModal";
import SituationModal from "../../components/Modals/SituationModal";
import SituationCreateModal from "../../components/CreateModals/SituationCreateModal";
import AdversaireModal from "../../components/Modals/AdversaireModal";
import AdversaireCreateModal from "../../components/CreateModals/AdversaireCreateModal";
import DeleteIcon from "@mui/icons-material/Delete";
import TypePoursuiteModal from "../../components/Modals/TypePoursuiteModal";
import TypePoursuiteCreateModal from "../../components/CreateModals/TypePoursuiteCreateModal";
import TypeGarantieModal from "../../components/Modals/TypeGarantieModal";
import TypeGarantieCreateModal from "../../components/CreateModals/TypeGarantieCreateModal";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SelectChangeEvent } from "@mui/material/Select";
import VehiculeModal from "../../components/Modals/VehiculeModal";
import VehiculeCreateModal from "../../components/CreateModals/VehiculeCreateModal";
import TypeAccidentTravailCreateModal from "../../components/CreateModals/TypeAccidentTravailCreateModal";
import TypeAccidentTravailModal from "../../components/Modals/TypeAccidentTravailModal";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  id_affaire: number;
  reference: string;
  referenceprovisoire: string;
  referencedefinitive: string;
  libelle: string;
  genreaffaire_id: string;
  clients: ClientAffaire[];
  adversaires: AdversaireAffaire[];
  accidentcirculation?: MyFormValuesAccidentCirculation;
  accidenttravail?: MyFormValuesAccidentTravail;
}

interface ClientAffaire {
  client_id: string;
  situation_id: string;
}

interface AdversaireAffaire {
  adversaire_id: string;
  situation_id: string;
}

interface MyFormValuesAccidentTravail {
  observation: string;
  dateaccident: Date | string | null;
  numpoliceassurance: string;
  typeaccidenttravail_id: string;
}

interface MyFormValuesAccidentCirculation {
  dateaccident: Date | string | null;
  observation: string;
  typepoursuite_id: string;
  typegarantie_id: string;
  vehiculeadversaires: AdversaireVehicule[];
  vehiculeclients: ClientVehicule[];
}

interface ClientVehicule {
  client_id: string;
  vehicule_id: string;
}

interface AdversaireVehicule {
  adversaire_id: string;
  vehicule_id: string;
}

export default function EditAffaire() {
  const [loadingSend, setLoadingSend] = useState(false);
  //////////////////// Affaire ////////////////////////////////////////////
  const [listeClient, setListeClient] = useState<any>([]);
  const [schema, setSchema] = useState<any>("");
  const [listeAdversaire, setListeAdversaire] = useState<any>([]);

  const [openEdit, setOpenEdit] = useState(false);
  const [openClient, setOpenClient] = useState([false]);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [clientIndex, setClientIndex] = useState(0);
  const [displayClient, setDisplayClient] = useState([""]);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);
  const [initialValues, setInitialValues] = useState<MyFormValues>({
    id_affaire: 0,
    reference: "",
    referenceprovisoire: "",
    referencedefinitive: "",
    libelle: "",
    genreaffaire_id: "",
    clients: [
      {
        client_id: "",
        situation_id: "",
      },
    ],
    adversaires: [
      {
        adversaire_id: "",
        situation_id: "",
      },
    ],
  });

  const [openSituationClient, setOpenSituationClient] = useState([false]);
  const [openCreateSituationClient, setOpenCreateSituationClient] =
    useState(false);
  const [situationClientIndex, setSituationClientIndex] = useState(0);
  const [displaySituationClient, setDisplaySituationClient] = useState([""]);

  const [openAdversaire, setOpenAdversaire] = useState([false]);
  const [openCreateAdversaire, setOpenCreateAdversaire] = useState(false);
  const [adversaireIndex, setAdversaireIndex] = useState(0);
  const [displayAdversaire, setDisplayAdversaire] = useState([""]);

  const [openSituationAdversaire, setOpenSituationAdversaire] = useState([
    false,
  ]);
  const [openCreateSituationAdversaire, setOpenCreateSituationAdversaire] =
    useState(false);
  const [situationAdversaireIndex, setSituationAdversaireIndex] = useState(0);
  const [displaySituationAdversaire, setDisplaySituationAdversaire] = useState([
    "",
  ]);

  const [openCreateGenreAffaire, setOpenCreateGenreAffaire] = useState(false);
  const [openGenreAffaire, setOpenGenreAffaire] = useState(false);
  const [displayGenreAffaire, setDisplayGenreAffaire] = useState("");

  //////////////////// accident circulation /////////////////

  const [openVehiculeClient, setOpenVehiculeClient] = useState([false]);
  const [openCreateVehiculeClient, setOpenCreateVehiculeClient] =
    useState(false);
  const [clientVehiculeIndex, setClientVehiculeIndex] = useState(0);
  const [displayVehiculeClient, setDisplayVehiculeClient] = useState([""]);

  const [openCreateVehiculeAdversaire, setOpenCreateVehiculeAdversaire] =
    useState(false);
  const [adversaireVehiculeIndex, setAdversaireVehiculeIndex] = useState(0);

  const [openVehiculeAdversaire, setOpenVehiculeAdversaire] = useState([false]);
  const [displayVehiculeAdversaire, setDisplayVehiculeAdversaire] = useState([
    "",
  ]);

  const [openCreateTypeGarantie, setOpenCreateTypeGarantie] = useState(false);
  const [openTypeGarantie, setOpenTypeGarantie] = useState(false);
  const [displayTypeGarantie, setDisplayTypeGarantie] = useState("");

  const [openCreateTypePoursuite, setOpenCreateTypePoursuite] = useState(false);
  const [openTypePoursuite, setOpenTypePoursuite] = useState(false);
  const [displayTypePoursuite, setDisplayTypePoursuite] = useState("");

  ///////////////////////// accidenttravail ////////////////////////////////

  const [openCreateTypeAccidentTravail, setOpenCreateTypeAccidentTravail] =
    useState(false);
  const [openTypeAccidentTravail, setOpenTypeAccidentTravail] = useState(false);
  const [displayTypeAccidentTravail, setDisplayTypeAccidentTravail] =
    useState("");
  ////////////////////////////////////////////////////////////////////////

  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const affaire: affaireInterface = useSelector(
    (state: any) => state.affaire.affaire
  );

  let baseSchema = yup.object({
    reference: yup.string().required("Reference est obligatoire"),
    referenceprovisoire: yup
      .string()
      .required("Reference Provisoire est obligatoire"),
    referencedefinitive: yup
      .string()
      .required("Reference Défenitive est obligatoire"),
    //libelle: yup.string().required("Label est obligatoire"),
    genreaffaire_id: yup.string().required("Genre Affaire est obligatoire"),
    clients: yup
      .array()
      .of(
        yup.object().shape({
          client_id: yup.string().required("Client est obligatoire"),
          situation_id: yup.string().required("Qualité est obligatoire"),
        })
      )
      .min(1, "Client Affaire est obligatoire"),
    adversaires: yup
      .array()
      .of(
        yup.object().shape({
          adversaire_id: yup.string().required("Adversaire est obligatoire"),
          situation_id: yup.string().required("Qualité est obligatoire"),
        })
      )
      .min(1, "Adversaire Affaire est obligatoire"),
  });

  const getSchemaForDisplay = (display: string) => {
    if (display === "Accidents de Circulation") {
      let schema = baseSchema.shape({
        accidentcirculation: yup.object().shape({
          typepoursuite_id: yup
            .string()
            .required("Type poursuite est obligatoire"),
          typegarantie_id: yup
            .string()
            .required("Type garantie est obligatoire"),
          dateaccident: yup
            .string()
            .test("valid-date", "Invalid date", function (value) {
              return moment(value, "YYYY-MM-DD", true).isValid();
            }),
          vehiculeclients: yup
            .array()
            .of(
              yup.object().shape({
                client_id: yup.string().required("Client est obligatoire"),
                vehicule_id: yup.string().required("Vehicule est obligatoire"),
              })
            )
            .min(1, "Véhicule Client est obligatoire"),
        }),
      });
      setSchema(schema);
    } else if (display === "Accidents de Travail") {
      let schema = baseSchema.shape({
        accidenttravail: yup.object({
          numpoliceassurance: yup
            .string()
            .required("Num police assurance est obligatoire"),
          typeaccidenttravail_id: yup
            .string()
            .required("Type accident travail est obligatoire"),
          dateaccident: yup
            .string()
            .test("valid-date", "Invalid date", function (value) {
              return moment(value, "YYYY-MM-DD", true).isValid();
            }),
        }),
      });
      setSchema(schema);
    } else {
      setSchema(baseSchema); // Default schema if 'display' doesn't match any condition
    }
  };

  const loading = useSelector((state: any) => state.affaire.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneAffaire(id));
    } else {
      navigate("/affaires");
    }
  }, []);

  useEffect(() => {
    if (Object.keys(affaire).length > 0) {
      let arrayOpenAdversaire: boolean[] = [];
      let arrayOpenClient: boolean[] = [];
      let arrayDisplayClient: string[] = [];
      let arrayDisplayClientSituation: string[] = [];
      let arrayDisplayClientVehicule: string[] = [];
      let arrayDisplayAdversaire: string[] = [];
      let arrayDisplayAdversaireSituation: string[] = [];
      let arrayDisplayAdversaireVehicule: string[] = [];
      let listClients: any[] = [];
      let listAdversaires: any[] = [];

      arrayOpenAdversaire.length =
        affaire.adversaires?.length > 0 ? affaire.adversaires?.length : 1;
      arrayOpenAdversaire.fill(false);
      arrayOpenClient.length =
        affaire.clients?.length > 0 ? affaire.clients?.length : 1;
      arrayOpenClient.fill(false);

      let arrayClientVehicule: any[] = [];
      if (affaire.vehiculeclients.length > 0) {
        arrayClientVehicule = [
          ...affaire.vehiculeclients?.map((element, i) => {
            arrayDisplayClientVehicule.push(
              element.Vehicule?.numpoliceassurance ?? "-"
            );
            return {
              client_id: element.client_id,
              vehicule_id: element.vehicule_id,
            };
          }),
        ];
      } else {
        arrayDisplayClientVehicule.push("");
        arrayClientVehicule = [
          {
            client_id: "",
            vehicule_id: "",
          },
        ];
      }

      let arrayAdversaireVehicule: any[] = [];
      if (affaire.vehiculeadversaires.length > 0) {
        arrayAdversaireVehicule = [
          ...affaire.vehiculeadversaires?.map((element, i) => {
            arrayDisplayAdversaireVehicule.push(
              element.Vehicule?.numpoliceassurance ?? "-"
            );
            return {
              adversaire_id: element.adversaire_id,
              vehicule_id: element.vehicule_id,
            };
          }),
        ];
      } else {
        arrayDisplayAdversaireVehicule.push("");
        arrayAdversaireVehicule = [
          {
            adversaire_id: "",
            vehicule_id: "",
          },
        ];
      }

      let arrayClientAffaire: any[] = [];
      if (affaire.clients.length > 0) {
        arrayClientAffaire = [
          ...affaire.clients?.map((element, i) => {
            listClients.push(element.Client);
            arrayDisplayClient.push(
              (element.Client?.nom ? element.Client?.nom + " " : "") +
                (element.Client?.prenom ?? "")
            );
            arrayDisplayClientSituation.push(element.Situation?.libelle ?? "-");
            return {
              client_id: element.client_id,
              situation_id: element.situation_id,
            };
          }),
        ];
      } else {
        arrayDisplayClient.push("");
        arrayDisplayClientSituation.push("");
        arrayClientAffaire = [
          {
            client_id: "",
            situation_id: "",
          },
        ];
      }
      let arrayAdversaireAffaire: any[] = [];
      if (affaire.adversaires.length > 0) {
        arrayAdversaireAffaire = [
          ...affaire.adversaires?.map((element, i) => {
            listAdversaires.push(element.Adversaire);
            arrayDisplayAdversaire.push(
              element.Adversaire?.nom + " " + element.Adversaire?.prenom
            );
            arrayDisplayAdversaireSituation.push(
              element.Situation?.libelle ?? "-"
            );
            return {
              adversaire_id: element.adversaire_id,
              situation_id: element.situation_id,
            };
          }),
        ];
      } else {
        arrayDisplayAdversaire.push("");
        arrayDisplayAdversaireSituation.push("");
        arrayAdversaireAffaire = [
          {
            adversaire_id: "",
            situation_id: "",
          },
        ];
      }

      let initialValues: MyFormValues = {
        id_affaire: affaire.id_affaire,
        reference: affaire.reference,
        referenceprovisoire: affaire.referenceprovisoire,
        referencedefinitive: affaire.referencedefinitive,
        libelle: affaire.libelle,
        genreaffaire_id: affaire.genreaffaire_id,
        clients: [...arrayClientAffaire],
        adversaires: [...arrayAdversaireAffaire],
      };

      if (affaire.accidentcirculations.length > 0) {
        let accidentcirculation: MyFormValuesAccidentCirculation = {
          dateaccident: affaire.accidentcirculations[0].dateaccident,
          observation: affaire.accidentcirculations[0].observation,
          typepoursuite_id: affaire.accidentcirculations[0].typepoursuite_id
            ? affaire.accidentcirculations[0].typepoursuite_id.toString()
            : "",
          typegarantie_id: affaire.accidentcirculations[0].typegarantie_id
            ? affaire.accidentcirculations[0].typegarantie_id.toString()
            : "",
          vehiculeclients: arrayClientVehicule,
          vehiculeadversaires: arrayAdversaireVehicule,
        };
        initialValues.accidentcirculation = accidentcirculation;
        setDisplayTypeGarantie(
          affaire.accidentcirculations[0].TypeGarantie?.libelle
        );
        setDisplayTypePoursuite(
          affaire.accidentcirculations[0].TypePoursuite?.libelle
        );
      }
      if (affaire.accidenttravails.length > 0) {
        let accidenttravail: MyFormValuesAccidentTravail = {
          observation: affaire.accidenttravails[0].observation,
          dateaccident: affaire.accidenttravails[0].dateaccident,
          numpoliceassurance: affaire.accidenttravails[0].numpoliceassurance,
          typeaccidenttravail_id: affaire.accidenttravails[0]
            .typeaccidenttravail_id
            ? affaire.accidenttravails[0].typeaccidenttravail_id.toString()
            : "",
        };
        initialValues.accidenttravail = accidenttravail;
        setDisplayTypeAccidentTravail(
          affaire.accidenttravails[0].TypeAccidentTravail?.libelle
        );
      }

      setOpenClient(arrayOpenClient);
      setDisplayClient(arrayDisplayClient);
      setOpenSituationClient(arrayOpenClient);
      setDisplaySituationClient(arrayDisplayClientSituation);

      setOpenAdversaire(arrayOpenAdversaire);
      setDisplayAdversaire(arrayDisplayAdversaire);
      setOpenSituationAdversaire(arrayOpenAdversaire);
      setDisplaySituationAdversaire(arrayDisplayAdversaireSituation);

      setDisplayVehiculeAdversaire(arrayDisplayAdversaireVehicule);
      setDisplayVehiculeClient(arrayDisplayClientVehicule);

      setListeClient(listClients);
      setListeAdversaire(listAdversaires);
      getSchemaForDisplay(affaire?.GenreAffaire?.libelle);
      setDisplayGenreAffaire(affaire?.GenreAffaire?.libelle);
      setInitialValues(initialValues);
      setTimeout(() => {
        setLoadingInitialValues(true);
      }, 500);
    }
  }, [affaire]);

  const refrechData = () => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneAffaire(id));
    }
  };

  ///////////// Affaire /////////////////
  const handleRemoveClientAffaire = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: any
  ) => {
    let arrayListeClient = listeClient;
    arrayListeClient = arrayListeClient.filter(
      (_ele: any, index: number) => index !== indexNum
    );
    setListeClient(arrayListeClient);
    let arrayOpenClient = openClient.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayDisplayClient = displayClient.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayOpenSituationClient = openSituationClient.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayDisplaySituationClient = displaySituationClient.filter(
      (_ele, index) => index !== indexNum
    );

    const currentClientAffaire = values.clients;
    const updatedClientAffaire = currentClientAffaire.filter(
      (_ele, index) => index !== indexNum
    );

    setOpenClient(arrayOpenClient);
    setDisplayClient(arrayDisplayClient);
    setOpenSituationClient(arrayOpenSituationClient);
    setDisplaySituationClient(arrayDisplaySituationClient);
    setFieldValue("clients", updatedClientAffaire);
  };
  const handleRemoveAdversaireAffaire = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: any
  ) => {
    let arrayListeAdversaire = listeAdversaire;
    arrayListeAdversaire = arrayListeAdversaire.filter(
      (_ele: any, index: number) => index !== indexNum
    );
    setListeAdversaire(arrayListeAdversaire);
    let arrayOpenAdversaire = openAdversaire.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayDisplayAdversaire = displayAdversaire.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayOpenSituationAdversaire = openSituationAdversaire.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayDisplaySituationAdversaire = displaySituationAdversaire.filter(
      (_ele, index) => index !== indexNum
    );
    const currentAdversaireAffaire = values.adversaires;
    const updatedAdversaireAffaire = currentAdversaireAffaire.filter(
      (_ele, index) => index !== indexNum
    );
    setOpenAdversaire(arrayOpenAdversaire);
    setDisplayAdversaire(arrayDisplayAdversaire);
    setOpenSituationAdversaire(arrayOpenSituationAdversaire);
    setDisplaySituationAdversaire(arrayDisplaySituationAdversaire);
    setFieldValue("adversaires", updatedAdversaireAffaire);
  };
  const handleAddClientAffaire = (values: MyFormValues, setFieldValue: any) => {
    const newClientAffaire = {
      client_id: "",
      situation_id: "",
    };
    let arrayOpenClient = [...openClient, false];
    let arrayDisplayClient = [...displayClient, ""];
    let arrayOpenSituationClient = [...openSituationClient, false];
    let arrayDisplaySituationClient = [...displaySituationClient, ""];

    setOpenClient(arrayOpenClient);
    setDisplayClient(arrayDisplayClient);
    setOpenSituationClient(arrayOpenSituationClient);
    setDisplaySituationClient(arrayDisplaySituationClient);
    const currentClientAffaire = values.clients;
    const updatedClientAffaire = [...currentClientAffaire, newClientAffaire];
    setFieldValue("clients", updatedClientAffaire);
  };
  const handleAddAdversaireAffaire = (
    values: MyFormValues,
    setFieldValue: any
  ) => {
    const newAdversaireAffaire = {
      adversaire_id: "",
      situation_id: "",
    };
    let arrayOpenAdversaire = [...openAdversaire, false];
    let arrayDisplayAdversaire = [...displayAdversaire, ""];
    let arrayOpenSituationAdversaire = [...openSituationAdversaire, false];
    let arrayDisplaySituationAdversaire = [...displaySituationAdversaire, ""];

    setOpenAdversaire(arrayOpenAdversaire);
    setDisplayAdversaire(arrayDisplayAdversaire);
    setOpenSituationAdversaire(arrayOpenSituationAdversaire);
    setDisplaySituationAdversaire(arrayDisplaySituationAdversaire);

    const currentAdversaireAffaire = values.adversaires;
    const updatedAdversaireAffaire = [
      ...currentAdversaireAffaire,
      newAdversaireAffaire,
    ];
    setFieldValue("adversaires", updatedAdversaireAffaire);
  };

  ///////////// Accident Circulation /////////////////
  const handleRemoveClientVehicule = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    let arrayOpenVehiculeClient = openVehiculeClient.filter(
      (ele, index) => index !== indexNum
    );
    let arrayDisplayVehiculeClient = displayVehiculeClient.filter(
      (ele, index) => index !== indexNum
    );

    const currentAc = values.accidentcirculation;
    const currentClientVehicule = currentAc?.vehiculeclients;
    let updatedClientVehicule: any[] = [];
    if (currentClientVehicule && currentAc) {
      updatedClientVehicule = currentClientVehicule?.filter(
        (ele, index) => index !== indexNum
      );
      currentAc.vehiculeclients = updatedClientVehicule;
      setFieldValue("accidentcirculation", currentAc);
      setOpenVehiculeClient(arrayOpenVehiculeClient);
      setDisplayVehiculeClient(arrayDisplayVehiculeClient);
    }
  };
  const handleRemoveAdversaireVehicule = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    let arrayOpenVehiculeAdversaire = openVehiculeAdversaire.filter(
      (ele, index) => index !== indexNum
    );
    let arrayDisplayVehiculeAdversaire = displayVehiculeAdversaire.filter(
      (ele, index) => index !== indexNum
    );

    const currentAc = values.accidentcirculation;
    const currentAdversaireVehicule = currentAc?.vehiculeadversaires;
    let updatedAdversaireVehicule: any[] = [];
    if (currentAdversaireVehicule && currentAc) {
      updatedAdversaireVehicule = currentAdversaireVehicule?.filter(
        (ele, index) => index !== indexNum
      );
      currentAc.vehiculeadversaires = updatedAdversaireVehicule;
      setFieldValue("accidentcirculation", currentAc);
      setOpenVehiculeAdversaire(arrayOpenVehiculeAdversaire);
      setDisplayVehiculeAdversaire(arrayDisplayVehiculeAdversaire);
    }
  };
  const handleAddClientVehicule = (
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    const newClientVehicule = {
      client_id: "",
      vehicule_id: "",
    };
    let arrayOpenVehiculeClient = [...openVehiculeClient, false];
    let arrayDisplayVehiculeClient = [...displayVehiculeClient, ""];

    setOpenVehiculeClient(arrayOpenVehiculeClient);
    setDisplayVehiculeClient(arrayDisplayVehiculeClient);
    const currentAc = values.accidentcirculation;
    const currentClientVehicule = currentAc?.vehiculeclients;
    let updatedClientVehicule: any[] = [];
    if (currentClientVehicule && currentAc) {
      updatedClientVehicule = [...currentClientVehicule, newClientVehicule];
      currentAc.vehiculeclients = updatedClientVehicule;
      setFieldValue("accidentcirculation", currentAc);
    }
  };
  const handleAddAdversaireVehicule = (
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    const newAdversaireVehicule = {
      adversaire_id: "",
      vehicule_id: "",
    };
    let arrayOpenVehiculeAdversaire = [...openVehiculeAdversaire, false];
    let arrayDisplayVehiculeAdversaire = [...displayVehiculeAdversaire, ""];

    setOpenVehiculeAdversaire(arrayOpenVehiculeAdversaire);
    setDisplayVehiculeAdversaire(arrayDisplayVehiculeAdversaire);

    const currentAc = values.accidentcirculation;
    const currentAdversaireVehicule = currentAc?.vehiculeadversaires;
    let updatedAdversaireVehicule: any[] = [];
    if (currentAdversaireVehicule && currentAc) {
      updatedAdversaireVehicule = [
        ...currentAdversaireVehicule,
        newAdversaireVehicule,
      ];
      currentAc.vehiculeadversaires = updatedAdversaireVehicule;
      setFieldValue("accidentcirculation", currentAc);
    }
  };

  const handleSetOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  const CreateProcedure = () => {
    localStorage.setItem("affaire", JSON.stringify(affaire));
    navigate("/procedures/new");
  };

  const checkVehiculeAdversairesIsNotEmpty = (
    vehiculeadversaires: undefined | AdversaireVehicule[]
  ) => {
    let retur = true;
    if (vehiculeadversaires?.length === 1) {
      retur = true;
    } else {
      vehiculeadversaires?.forEach((element) => {
        if (
          element.adversaire_id.toString() === "" ||
          element.vehicule_id.toString() === ""
        ) {
          retur = false;
        }
      });
    }
    return retur;
  };

  return (
    <>
      {openEdit ? (
        <>
          <div className={styles.ReturnList}>
            <div />
            <h2>Editer Affaire</h2>
            <div />
          </div>
          {loadingInitialValues && Object.keys(initialValues).length > 0 ? (
            <Formik
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={initialValues}
              onSubmit={async (values) => {
                if (
                  checkVehiculeAdversairesIsNotEmpty(
                    values.accidentcirculation?.vehiculeadversaires
                  )
                ) {
                  if (
                    values.accidentcirculation?.vehiculeadversaires?.length ===
                    1
                  ) {
                    if (
                      values.accidentcirculation?.vehiculeadversaires[0].adversaire_id.toString() ===
                        "" ||
                      values.accidentcirculation?.vehiculeadversaires[0].vehicule_id.toString() ===
                        ""
                    ) {
                      let object = values.accidentcirculation;
                      delete object.vehiculeadversaires;
                      values.accidentcirculation = object;
                    }
                  }
                  setLoadingSend(true);
                  const bool = await dispatch(
                    editAffaire(values, values.id_affaire)
                  );
                  if (bool) {
                    MySwal.fire({
                      icon: "success",
                      title: "Affaire est modifié avec succes",
                      customClass: {
                        confirmButton: "shadow-none",
                      },
                    }).then(() => {
                      setLoadingSend(false);
                      refrechData();
                      setOpenEdit(false);
                      //navigate("/affaires");
                    });
                  }
                } else {
                  MySwal.fire({
                    icon: "warning",
                    title: "Il y a des champs Véhicule Adversaire Vides",
                    text: "Veuillez SVP, supprimer la ligne vide ou la remplir",
                    customClass: {
                      confirmButton: "shadow-none",
                    },
                  });
                }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        label="Reference"
                        name="reference"
                        onChange={handleChange}
                        value={values.reference}
                        fullWidth
                        error={!!errors.reference}
                        helperText={errors.reference ? errors.reference : ""}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Reference Provisoire"
                        name="referenceprovisoire"
                        onChange={handleChange}
                        value={values.referenceprovisoire}
                        fullWidth
                        error={!!errors.referenceprovisoire}
                        helperText={
                          errors.referenceprovisoire
                            ? errors.referenceprovisoire
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Reference Definitive"
                        name="referencedefinitive"
                        onChange={handleChange}
                        value={values.referencedefinitive}
                        fullWidth
                        error={!!errors.referencedefinitive}
                        helperText={
                          errors.referencedefinitive
                            ? errors.referencedefinitive
                            : ""
                        }
                      />
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayGenreAffaire
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Genre Affaire
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayGenreAffaire}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenGenreAffaire(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Genre Affaire"
                          />
                        </FormControl>
                        {openGenreAffaire && (
                          <GenreAffaireModal
                            open={openGenreAffaire}
                            handleClose={() => setOpenGenreAffaire(false)}
                            handleChangeValue={(value) => {
                              setFieldValue("genreaffaire_id", value);
                            }}
                            handleChangeDisplay={(value) => {
                              if (value === "Accidents de Circulation") {
                                delete values.accidenttravail;
                                setDisplayTypeGarantie("");
                                setDisplayTypePoursuite("");
                                values.accidentcirculation = {
                                  observation: "",
                                  typepoursuite_id: "",
                                  typegarantie_id: "",
                                  dateaccident: null,
                                  vehiculeclients: [
                                    {
                                      client_id: "",
                                      vehicule_id: "",
                                    },
                                  ],
                                  vehiculeadversaires: [
                                    {
                                      adversaire_id: "",
                                      vehicule_id: "",
                                    },
                                  ],
                                };
                              } else {
                                if (value === "Accidents de Travail") {
                                  delete values.accidentcirculation;
                                  setDisplayTypeAccidentTravail("");
                                  values.accidenttravail = {
                                    observation: "",
                                    numpoliceassurance: "",
                                    typeaccidenttravail_id: "",
                                    dateaccident: null,
                                  };
                                } else {
                                  delete values.accidentcirculation;
                                  delete values.accidenttravail;
                                }
                              }
                              setDisplayGenreAffaire(value);
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateGenreAffaire(true)}
                        >
                          <AddIcon />
                        </Button>
                        {openCreateGenreAffaire && (
                          <GenreAffaireCreateModal
                            open={openCreateGenreAffaire}
                            handleClose={() => setOpenCreateGenreAffaire(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("genreaffaire_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayGenreAffaire(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.genreaffaire_id && (
                        <span className={styles.errorHandle}>
                          {errors.genreaffaire_id}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} className={styles.ArrayInput}>
                      {values.clients.map((_element, index) => {
                        return (
                          <Grid
                            key={"client-" + index}
                            container
                            spacing={2}
                            className="mb-1"
                          >
                            <Grid item xs={2}>
                              <span className={styles.LabelArray}>
                                {index === 0 ? "Clients :" : ""}
                              </span>
                            </Grid>
                            <Grid container item xs={4}>
                              <Grid item xs={10}>
                                <FormControl
                                  className={
                                    !displayClient[index]
                                      ? styles.backgroudGrey
                                      : styles.backgroudGrey2
                                  }
                                  fullWidth
                                >
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Client
                                  </InputLabel>
                                  <OutlinedInput
                                    type="text"
                                    disabled
                                    fullWidth
                                    value={displayClient[index]}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setOpenClient((prevState) => {
                                              const newState = [...prevState];
                                              newState[index] = true;
                                              return newState;
                                            })
                                          }
                                          edge="end"
                                        >
                                          <SearchIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Client"
                                  />
                                </FormControl>
                                {openClient[index] && (
                                  <ClientModal
                                    open={openClient[index]}
                                    handleClose={() =>
                                      setOpenClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = false;
                                        return newState;
                                      })
                                    }
                                    handleChangeValue={(value) => {
                                      const currentClientAffaire =
                                        values.clients;
                                      const currentClient =
                                        currentClientAffaire[index];
                                      currentClient.client_id = value;
                                      const updatedClientAffaire = [
                                        ...currentClientAffaire,
                                      ];
                                      setFieldValue(
                                        "clients",
                                        updatedClientAffaire
                                      );
                                    }}
                                    handleChangeDisplay={(value) =>
                                      setDisplayClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = value;
                                        return newState;
                                      })
                                    }
                                    handleChangeClient={(client) => {
                                      let currentClient = listeClient;
                                      currentClient[index] = client;
                                      setListeClient(currentClient);
                                    }}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  className={styles.AddButtonModal}
                                  onClick={() => {
                                    setClientIndex(index);
                                    setOpenCreateClient(true);
                                  }}
                                >
                                  +
                                </Button>
                                {openCreateClient && (
                                  <ClientCreateModal
                                    open={openCreateClient}
                                    handleClose={() =>
                                      setOpenCreateClient(false)
                                    }
                                    handleChangeValue={(value) => {
                                      const currentClientAffaire =
                                        values.clients;
                                      const currentClient =
                                        currentClientAffaire[clientIndex];

                                      currentClient.client_id = value;
                                      const updatedClientAffaire = [
                                        ...currentClientAffaire,
                                      ];
                                      setFieldValue(
                                        "clients",
                                        updatedClientAffaire
                                      );
                                    }}
                                    handleChangeDisplay={(value) =>
                                      setDisplayClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[clientIndex] = value;
                                        return newState;
                                      })
                                    }
                                    handleChangeClient={(client) => {
                                      let currentClient = listeClient;
                                      currentClient[index] = client;
                                      setListeClient(currentClient);
                                    }}
                                  />
                                )}
                              </Grid>
                              {errors.clients && errors.clients[index] && (
                                <span className={styles.errorHandle}>
                                  {(errors.clients[index] as any)?.client_id}
                                </span>
                              )}
                            </Grid>
                            <Grid container item xs={4}>
                              <Grid item xs={10}>
                                <FormControl
                                  className={
                                    !displaySituationClient[index]
                                      ? styles.backgroudGrey
                                      : styles.backgroudGrey2
                                  }
                                  fullWidth
                                >
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Qualité
                                  </InputLabel>
                                  <OutlinedInput
                                    type="text"
                                    disabled
                                    fullWidth
                                    value={displaySituationClient[index]}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setOpenSituationClient(
                                              (prevState) => {
                                                const newState = [...prevState];
                                                newState[index] = true;
                                                return newState;
                                              }
                                            )
                                          }
                                          edge="end"
                                        >
                                          <SearchIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Qualité"
                                  />
                                </FormControl>
                                {openSituationClient[index] && (
                                  <SituationModal
                                    open={openSituationClient[index]}
                                    handleClose={() =>
                                      setOpenSituationClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = false;
                                        return newState;
                                      })
                                    }
                                    handleChangeValue={(value) => {
                                      const currentClientAffaire =
                                        values.clients;
                                      const currentClient =
                                        currentClientAffaire[index];
                                      currentClient.situation_id = value;
                                      const updatedClientAffaire = [
                                        ...currentClientAffaire,
                                      ];
                                      setFieldValue(
                                        "clients",
                                        updatedClientAffaire
                                      );
                                    }}
                                    handleChangeDisplay={(value) =>
                                      setDisplaySituationClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = value;
                                        return newState;
                                      })
                                    }
                                  />
                                )}
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  className={styles.AddButtonModal}
                                  onClick={() => {
                                    setSituationClientIndex(index);
                                    setOpenCreateSituationClient(true);
                                  }}
                                >
                                  +
                                </Button>
                                {openCreateSituationClient && (
                                  <SituationCreateModal
                                    open={openCreateSituationClient}
                                    handleClose={() =>
                                      setOpenCreateSituationClient(false)
                                    }
                                    handleChangeValue={(value) => {
                                      const currentClientAffaire =
                                        values.clients;
                                      const currentClient =
                                        currentClientAffaire[
                                          situationClientIndex
                                        ];
                                      currentClient.situation_id = value;
                                      const updatedClientAffaire = [
                                        ...currentClientAffaire,
                                      ];
                                      setFieldValue(
                                        "clients",
                                        updatedClientAffaire
                                      );
                                    }}
                                    handleChangeDisplay={(value) =>
                                      setDisplaySituationClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[situationClientIndex] = value;
                                        return newState;
                                      })
                                    }
                                  />
                                )}
                              </Grid>
                              {errors.clients && errors.clients[index] && (
                                <span className={styles.errorHandle}>
                                  {(errors.clients[index] as any)?.situation_id}
                                </span>
                              )}
                            </Grid>
                            <Grid item xs={2}>
                              <div className={styles.GroupeButton}>
                                {index === 0 ? (
                                  <span className={styles.ButtonArray}>
                                    <Button
                                      onClick={() =>
                                        handleAddClientAffaire(
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <AddIcon />
                                    </Button>
                                  </span>
                                ) : (
                                  ""
                                )}
                                {index !== 0 ? (
                                  <span className={styles.ButtonDelete}>
                                    <Button
                                      onClick={() =>
                                        handleRemoveClientAffaire(
                                          index,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item xs={12} className={styles.ArrayInput}>
                      {values.adversaires.map((_element, index) => {
                        return (
                          <Grid
                            key={"adversaire-" + index}
                            container
                            spacing={2}
                            className="mb-1"
                          >
                            <Grid item xs={2}>
                              <span className={styles.LabelArray}>
                                {index === 0 ? "Adversaires :" : ""}
                              </span>
                            </Grid>
                            <Grid container item xs={4}>
                              <Grid item xs={10}>
                                <FormControl
                                  className={
                                    !displayAdversaire[index]
                                      ? styles.backgroudGrey
                                      : styles.backgroudGrey2
                                  }
                                  fullWidth
                                >
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Adversaire
                                  </InputLabel>
                                  <OutlinedInput
                                    type="text"
                                    disabled
                                    fullWidth
                                    value={displayAdversaire[index]}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setOpenAdversaire((prevState) => {
                                              const newState = [...prevState];
                                              newState[index] = true;
                                              return newState;
                                            })
                                          }
                                          edge="end"
                                        >
                                          <SearchIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Adversaire"
                                  />
                                </FormControl>
                                {openAdversaire[index] && (
                                  <AdversaireModal
                                    open={openAdversaire[index]}
                                    handleClose={() =>
                                      setOpenAdversaire((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = false;
                                        return newState;
                                      })
                                    }
                                    handleChangeValue={(value) => {
                                      const currentAdversaireAffaire =
                                        values.adversaires;
                                      const currentAdversaire =
                                        currentAdversaireAffaire[index];
                                      currentAdversaire.adversaire_id = value;
                                      const updatedAdversaireAffaire = [
                                        ...currentAdversaireAffaire,
                                      ];
                                      setFieldValue(
                                        "adversaires",
                                        updatedAdversaireAffaire
                                      );
                                    }}
                                    handleChangeDisplay={(value) =>
                                      setDisplayAdversaire((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = value;
                                        return newState;
                                      })
                                    }
                                    handleChangeAdversaire={(adversaire) => {
                                      let currentAdversaire = listeAdversaire;
                                      currentAdversaire[index] = adversaire;
                                      setListeAdversaire(currentAdversaire);
                                    }}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  className={styles.AddButtonModal}
                                  onClick={() => {
                                    setAdversaireIndex(index);
                                    setOpenCreateAdversaire(true);
                                  }}
                                >
                                  +
                                </Button>
                                {openCreateAdversaire && (
                                  <AdversaireCreateModal
                                    open={openCreateAdversaire}
                                    handleClose={() =>
                                      setOpenCreateAdversaire(false)
                                    }
                                    handleChangeValue={(value) => {
                                      const currentAdversaireAffaire =
                                        values.adversaires;
                                      const currentAdversaire =
                                        currentAdversaireAffaire[
                                          adversaireIndex
                                        ];
                                      currentAdversaire.adversaire_id = value;
                                      const updatedAdversaireAffaire = [
                                        ...currentAdversaireAffaire,
                                      ];
                                      setFieldValue(
                                        "adversaires",
                                        updatedAdversaireAffaire
                                      );
                                    }}
                                    handleChangeDisplay={(value) => {
                                      setDisplayAdversaire((prevState) => {
                                        const newState = [...prevState];
                                        newState[adversaireIndex] = value;
                                        return newState;
                                      });
                                    }}
                                    handleChangeAdversaire={(adversaire) => {
                                      let currentAdversaire = listeAdversaire;
                                      currentAdversaire[index] = adversaire;
                                      setListeAdversaire(currentAdversaire);
                                    }}
                                  />
                                )}
                              </Grid>
                              {errors.adversaires &&
                                errors.adversaires[index] && (
                                  <span className={styles.errorHandle}>
                                    {
                                      (errors.adversaires[index] as any)
                                        ?.adversaire_id
                                    }
                                  </span>
                                )}
                            </Grid>
                            <Grid container item xs={4}>
                              <Grid item xs={10}>
                                <FormControl
                                  className={
                                    !displaySituationAdversaire[index]
                                      ? styles.backgroudGrey
                                      : styles.backgroudGrey2
                                  }
                                  fullWidth
                                >
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Qualité
                                  </InputLabel>
                                  <OutlinedInput
                                    type="text"
                                    disabled
                                    fullWidth
                                    value={displaySituationAdversaire[index]}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setOpenSituationAdversaire(
                                              (prevState) => {
                                                const newState = [...prevState];
                                                newState[index] = true;
                                                return newState;
                                              }
                                            )
                                          }
                                          edge="end"
                                        >
                                          <SearchIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Qualité"
                                  />
                                </FormControl>
                                {openSituationAdversaire[index] && (
                                  <SituationModal
                                    open={openSituationAdversaire[index]}
                                    handleClose={() =>
                                      setOpenSituationAdversaire(
                                        (prevState) => {
                                          const newState = [...prevState];
                                          newState[index] = false;
                                          return newState;
                                        }
                                      )
                                    }
                                    handleChangeValue={(value) => {
                                      const currentAdversaireAffaire =
                                        values.adversaires;
                                      const currentAdversaire =
                                        currentAdversaireAffaire[index];
                                      currentAdversaire.situation_id = value;
                                      const updatedAdversaireAffaire = [
                                        ...currentAdversaireAffaire,
                                      ];
                                      setFieldValue(
                                        "adversaires",
                                        updatedAdversaireAffaire
                                      );
                                    }}
                                    handleChangeDisplay={(value) =>
                                      setDisplaySituationAdversaire(
                                        (prevState) => {
                                          const newState = [...prevState];
                                          newState[index] = value;
                                          return newState;
                                        }
                                      )
                                    }
                                  />
                                )}
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  className={styles.AddButtonModal}
                                  onClick={() => {
                                    setSituationAdversaireIndex(index);
                                    setOpenCreateSituationAdversaire(true);
                                  }}
                                >
                                  +
                                </Button>
                                {openCreateSituationAdversaire && (
                                  <SituationCreateModal
                                    open={openCreateSituationAdversaire}
                                    handleClose={() =>
                                      setOpenCreateSituationAdversaire(false)
                                    }
                                    handleChangeValue={(value) => {
                                      const currentAdversaireAffaire =
                                        values.adversaires;
                                      const currentAdversaire =
                                        currentAdversaireAffaire[
                                          situationAdversaireIndex
                                        ];
                                      currentAdversaire.situation_id = value;
                                      const updatedAdversaireAffaire = [
                                        ...currentAdversaireAffaire,
                                      ];
                                      setFieldValue(
                                        "adversaires",
                                        updatedAdversaireAffaire
                                      );
                                    }}
                                    handleChangeDisplay={(value) => {
                                      setDisplaySituationAdversaire(
                                        (prevState) => {
                                          const newState = [...prevState];
                                          newState[situationAdversaireIndex] =
                                            value;
                                          return newState;
                                        }
                                      );
                                    }}
                                  />
                                )}
                              </Grid>

                              {errors.adversaires &&
                                errors.adversaires[index] && (
                                  <span className={styles.errorHandle}>
                                    {
                                      (errors.adversaires[index] as any)
                                        ?.situation_id
                                    }
                                  </span>
                                )}
                            </Grid>
                            <Grid item xs={2}>
                              <div className={styles.GroupeButton}>
                                {index === 0 ? (
                                  <span className={styles.ButtonArray}>
                                    <Button
                                      onClick={() =>
                                        handleAddAdversaireAffaire(
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <AddIcon />
                                    </Button>
                                  </span>
                                ) : (
                                  ""
                                )}
                                {index !== 0 ? (
                                  <span className={styles.ButtonDelete}>
                                    <Button
                                      onClick={() =>
                                        handleRemoveAdversaireAffaire(
                                          index,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {displayGenreAffaire === "Accidents de Circulation" && (
                      <Grid item container spacing={2}>
                        <Grid container item xs={4}>
                          <Grid item xs={10}>
                            <FormControl
                              className={
                                !displayTypePoursuite
                                  ? styles.backgroudGrey
                                  : styles.backgroudGrey2
                              }
                              fullWidth
                            >
                              <InputLabel htmlFor="outlined-adornment-password">
                                Type Poursuite
                              </InputLabel>
                              <OutlinedInput
                                type="text"
                                disabled
                                fullWidth
                                value={displayTypePoursuite}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setOpenTypePoursuite(true)}
                                      edge="end"
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Type Poursuite"
                              />
                            </FormControl>
                            {openTypePoursuite && (
                              <TypePoursuiteModal
                                open={openTypePoursuite}
                                handleClose={() => setOpenTypePoursuite(false)}
                                handleChangeValue={(value) => {
                                  const currentAccidentCircualtion =
                                    values.accidentcirculation;
                                  if (
                                    currentAccidentCircualtion?.typepoursuite_id
                                  )
                                    currentAccidentCircualtion.typepoursuite_id =
                                      value;

                                  setFieldValue(
                                    "accidentcirculation",
                                    currentAccidentCircualtion
                                  );
                                }}
                                handleChangeDisplay={setDisplayTypePoursuite}
                              />
                            )}
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              className={styles.AddButtonModal}
                              onClick={() => setOpenCreateTypePoursuite(true)}
                            >
                              +
                            </Button>
                            {openCreateTypePoursuite && (
                              <TypePoursuiteCreateModal
                                open={openCreateTypePoursuite}
                                handleClose={() =>
                                  setOpenCreateTypePoursuite(false)
                                }
                                handleChangeValue={(value) => {
                                  const currentAccidentCircualtion =
                                    values.accidentcirculation;
                                  if (
                                    currentAccidentCircualtion?.typepoursuite_id
                                  )
                                    currentAccidentCircualtion.typepoursuite_id =
                                      value;

                                  setFieldValue(
                                    "accidentcirculation",
                                    currentAccidentCircualtion
                                  );
                                }}
                                handleChangeDisplay={(value) =>
                                  setDisplayTypePoursuite(value)
                                }
                              />
                            )}
                          </Grid>
                          <span className={styles.errorHandle}>
                            {getIn(
                              errors,
                              "accidentcirculation.typepoursuite_id"
                            )
                              ? getIn(
                                  errors,
                                  "accidentcirculation.typepoursuite_id"
                                )
                              : ""}
                          </span>
                        </Grid>
                        <Grid container item xs={4}>
                          <Grid item xs={10}>
                            <FormControl
                              className={
                                !displayTypeGarantie
                                  ? styles.backgroudGrey
                                  : styles.backgroudGrey2
                              }
                              fullWidth
                            >
                              <InputLabel htmlFor="outlined-adornment-password">
                                Type Garantie
                              </InputLabel>
                              <OutlinedInput
                                type="text"
                                disabled
                                fullWidth
                                value={displayTypeGarantie}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setOpenTypeGarantie(true)}
                                      edge="end"
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Type Garantie"
                              />
                            </FormControl>
                            {openTypeGarantie && (
                              <TypeGarantieModal
                                open={openTypeGarantie}
                                handleClose={() => setOpenTypeGarantie(false)}
                                handleChangeValue={(value) => {
                                  const currentAccidentCircualtion =
                                    values.accidentcirculation;
                                  if (currentAccidentCircualtion)
                                    currentAccidentCircualtion.typegarantie_id =
                                      value;

                                  setFieldValue(
                                    "accidentcirculation",
                                    currentAccidentCircualtion
                                  );
                                }}
                                handleChangeDisplay={setDisplayTypeGarantie}
                              />
                            )}
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              className={styles.AddButtonModal}
                              onClick={() => setOpenCreateTypeGarantie(true)}
                            >
                              +
                            </Button>
                            {openCreateTypeGarantie && (
                              <TypeGarantieCreateModal
                                open={openCreateTypeGarantie}
                                handleClose={() =>
                                  setOpenCreateTypeGarantie(false)
                                }
                                handleChangeValue={(value) => {
                                  const currentAccidentCircualtion =
                                    values.accidentcirculation;
                                  if (currentAccidentCircualtion)
                                    currentAccidentCircualtion.typegarantie_id =
                                      value;

                                  setFieldValue(
                                    "accidentcirculation",
                                    currentAccidentCircualtion
                                  );
                                }}
                                handleChangeDisplay={(value) =>
                                  setDisplayTypeGarantie(value)
                                }
                              />
                            )}
                          </Grid>
                          <span className={styles.errorHandle}>
                            {getIn(
                              errors,
                              "accidentcirculation.typegarantie_id"
                            )
                              ? getIn(
                                  errors,
                                  "accidentcirculation.typegarantie_id"
                                )
                              : ""}
                          </span>
                        </Grid>
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              onChange={(value) => {
                                const date = moment(value).format("YYYY-MM-DD");
                                const currentAccidentCircualtion =
                                  values.accidentcirculation;
                                if (currentAccidentCircualtion)
                                  currentAccidentCircualtion.dateaccident =
                                    date;

                                setFieldValue(
                                  "accidentcirculation",
                                  currentAccidentCircualtion
                                );
                              }}
                              value={moment(
                                values.accidentcirculation?.dateaccident
                              )}
                              label="Date Accident"
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: !!getIn(
                                    errors,
                                    "accidentcirculation.dateaccident"
                                  ),
                                },
                              }}
                            />
                          </LocalizationProvider>
                          {getIn(
                            errors,
                            "accidentcirculation.dateaccident"
                          ) && (
                            <span className={styles.errorHandle}>
                              {getIn(
                                errors,
                                "accidentcirculation.dateaccident"
                              )}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Observation"
                            name="accidentcirculation.observation"
                            onChange={handleChange}
                            value={values.accidentcirculation?.observation}
                            fullWidth
                            error={
                              !!getIn(errors, "accidentcirculation.observation")
                            }
                            helperText={
                              getIn(errors, "accidentcirculation.observation")
                                ? getIn(
                                    errors,
                                    "accidentcirculation.observation"
                                  )
                                : ""
                            }
                            multiline
                            rows={3}
                          />
                        </Grid>
                        <Grid item xs={12} className={styles.ArrayInput}>
                          {values.accidentcirculation?.vehiculeclients.map(
                            (element, index) => {
                              return (
                                <Grid
                                  key={"accidentcirculation-" + index}
                                  container
                                  spacing={2}
                                  className="mb-1"
                                >
                                  <Grid item xs={2}>
                                    <span className={styles.LabelArray}>
                                      {index === 0 ? "Véhicule Client :" : ""}
                                    </span>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <FormControl
                                      className={styles.backgroudWhite}
                                      fullWidth
                                    >
                                      <InputLabel id="demo-simple-select-label">
                                        Client
                                      </InputLabel>
                                      <Select
                                        name="client_id"
                                        labelId="demo-simple-select-label"
                                        label="Client"
                                        value={
                                          values.accidentcirculation
                                            ?.vehiculeclients[index].client_id
                                        }
                                        onChange={(e: SelectChangeEvent) => {
                                          let currentAc =
                                            values.accidentcirculation;
                                          let currentClientVehicule =
                                            currentAc?.vehiculeclients;
                                          let currentClient;
                                          if (
                                            currentClientVehicule &&
                                            currentAc
                                          ) {
                                            currentClient =
                                              currentClientVehicule[index];
                                            currentClient.client_id = e.target
                                              .value as string;
                                            currentClientVehicule[index] =
                                              currentClient;
                                            currentAc.vehiculeclients =
                                              currentClientVehicule;
                                          }
                                          setFieldValue(
                                            "accidentcirculation",
                                            currentAc
                                          );
                                        }}
                                      >
                                        <MenuItem value="">-</MenuItem>
                                        {listeClient?.length > 0 &&
                                          listeClient.map(
                                            (client: any, index: any) => {
                                              if (client) {
                                                return (
                                                  <MenuItem
                                                    key={"listeclient-" + index}
                                                    value={client.id_client}
                                                  >
                                                    {!client.nom &&
                                                    !client.prenom
                                                      ? "-----"
                                                      : ""}
                                                    {client.nom
                                                      ? client.nom + " "
                                                      : "" + client.prenom
                                                      ? client.prenom
                                                      : ""}
                                                  </MenuItem>
                                                );
                                              }
                                            }
                                          )}
                                      </Select>
                                    </FormControl>
                                    {errors &&
                                      errors.accidentcirculation &&
                                      errors.accidentcirculation
                                        .vehiculeclients &&
                                      errors.accidentcirculation
                                        .vehiculeclients[index] && (
                                        <span className={styles.errorHandle}>
                                          {
                                            (
                                              errors.accidentcirculation
                                                .vehiculeclients[index] as any
                                            )?.client_id
                                          }
                                        </span>
                                      )}
                                  </Grid>
                                  <Grid container item xs={4}>
                                    <Grid item xs={10}>
                                      <FormControl
                                        className={
                                          !displayVehiculeClient[index]
                                            ? styles.backgroudGrey
                                            : styles.backgroudGrey2
                                        }
                                        fullWidth
                                      >
                                        <InputLabel htmlFor="outlined-adornment-password">
                                          Vehicule
                                        </InputLabel>
                                        <OutlinedInput
                                          type="text"
                                          disabled
                                          fullWidth
                                          value={displayVehiculeClient[index]}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                onClick={() =>
                                                  setOpenVehiculeClient(
                                                    (prevState) => {
                                                      const newState = [
                                                        ...prevState,
                                                      ];
                                                      newState[index] = true;
                                                      return newState;
                                                    }
                                                  )
                                                }
                                                edge="end"
                                              >
                                                <SearchIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          label="Vehicule"
                                        />
                                      </FormControl>
                                      {openVehiculeClient[index] && (
                                        <VehiculeModal
                                          open={openVehiculeClient[index]}
                                          handleClose={() =>
                                            setOpenVehiculeClient(
                                              (prevState) => {
                                                const newState = [...prevState];
                                                newState[index] = false;
                                                return newState;
                                              }
                                            )
                                          }
                                          handleChangeValue={(value) => {
                                            let currentAc =
                                              values.accidentcirculation;
                                            let currentClientVehicule =
                                              currentAc?.vehiculeclients;
                                            let currentClient;
                                            if (
                                              currentClientVehicule &&
                                              currentAc
                                            ) {
                                              currentClient =
                                                currentClientVehicule[index];
                                              currentClient.vehicule_id = value;
                                              currentClientVehicule[index] =
                                                currentClient;
                                              currentAc.vehiculeclients =
                                                currentClientVehicule;
                                            }
                                            setFieldValue(
                                              "accidentcirculation",
                                              currentAc
                                            );
                                          }}
                                          handleChangeDisplay={(value) =>
                                            setDisplayVehiculeClient(
                                              (prevState) => {
                                                const newState = [...prevState];
                                                newState[index] = value;
                                                return newState;
                                              }
                                            )
                                          }
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Button
                                        className={styles.AddButtonModal}
                                        onClick={() => {
                                          setClientVehiculeIndex(index);
                                          setOpenCreateVehiculeClient(true);
                                        }}
                                      >
                                        +
                                      </Button>
                                      {openCreateVehiculeClient && (
                                        <VehiculeCreateModal
                                          open={openCreateVehiculeClient}
                                          handleClose={() =>
                                            setOpenCreateVehiculeClient(false)
                                          }
                                          handleChangeValue={(value) => {
                                            let currentAc =
                                              values.accidentcirculation;
                                            let currentClientVehicule =
                                              currentAc?.vehiculeclients;
                                            let currentClient;
                                            if (
                                              currentClientVehicule &&
                                              currentAc
                                            ) {
                                              currentClient =
                                                currentClientVehicule[index];
                                              currentClient.vehicule_id = value;
                                              currentClientVehicule[index] =
                                                currentClient;
                                              currentAc.vehiculeclients =
                                                currentClientVehicule;
                                            }
                                            setFieldValue(
                                              "accidentcirculation",
                                              currentAc
                                            );
                                          }}
                                          handleChangeDisplay={(value) => {
                                            setDisplayVehiculeClient(
                                              (prevState) => {
                                                const newState = [...prevState];
                                                newState[clientVehiculeIndex] =
                                                  value;
                                                return newState;
                                              }
                                            );
                                          }}
                                        />
                                      )}
                                    </Grid>
                                    {errors &&
                                      errors.accidentcirculation &&
                                      errors.accidentcirculation
                                        .vehiculeclients &&
                                      errors.accidentcirculation
                                        .vehiculeclients[index] && (
                                        <span className={styles.errorHandle}>
                                          {
                                            (
                                              errors.accidentcirculation
                                                .vehiculeclients[index] as any
                                            )?.vehicule_id
                                          }
                                        </span>
                                      )}
                                  </Grid>
                                  <Grid item xs={2}>
                                    <div className={styles.GroupeButton}>
                                      {index === 0 ? (
                                        <span className={styles.ButtonArray}>
                                          <Button
                                            onClick={() =>
                                              handleAddClientVehicule(
                                                values,
                                                setFieldValue
                                              )
                                            }
                                          >
                                            <AddIcon />
                                          </Button>
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {index !== 0 ? (
                                        <span className={styles.ButtonDelete}>
                                          <Button
                                            onClick={() =>
                                              handleRemoveClientVehicule(
                                                index,
                                                values,
                                                setFieldValue
                                              )
                                            }
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                        <Grid item xs={12} className={styles.ArrayInput}>
                          {values.accidentcirculation?.vehiculeadversaires &&
                            values.accidentcirculation?.vehiculeadversaires.map(
                              (element: any, index: any) => {
                                return (
                                  <Grid
                                    key={"accidentcirculation-" + index}
                                    container
                                    spacing={2}
                                    className="mb-1"
                                  >
                                    <Grid item xs={2}>
                                      <span className={styles.LabelArray}>
                                        {index === 0
                                          ? "Véhicule Adversaire :"
                                          : ""}
                                      </span>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <FormControl
                                        className={styles.backgroudWhite}
                                        fullWidth
                                      >
                                        <InputLabel id="demo-simple-select-label">
                                          Adversaire
                                        </InputLabel>
                                        <Select
                                          name="adversaire_id"
                                          labelId="demo-simple-select-label"
                                          label="Adversaire"
                                          value={
                                            values.accidentcirculation
                                              ?.vehiculeadversaires[index]
                                              .adversaire_id
                                          }
                                          onChange={(e: SelectChangeEvent) => {
                                            let currentAc =
                                              values.accidentcirculation;
                                            let currentAdversaireVehicule =
                                              currentAc?.vehiculeadversaires;
                                            let currentAdversaire;
                                            if (
                                              currentAdversaireVehicule &&
                                              currentAc
                                            ) {
                                              currentAdversaire =
                                                currentAdversaireVehicule[
                                                  index
                                                ];
                                              currentAdversaire.adversaire_id =
                                                e.target.value as string;
                                              currentAdversaireVehicule[index] =
                                                currentAdversaire;
                                              currentAc.vehiculeadversaires =
                                                currentAdversaireVehicule;
                                            }
                                            setFieldValue(
                                              "accidentcirculation",
                                              currentAc
                                            );
                                          }}
                                        >
                                          <MenuItem value="">-</MenuItem>
                                          {listeAdversaire?.length > 0 &&
                                            listeAdversaire.map(
                                              (adversaire: any, index: any) => {
                                                if (adversaire) {
                                                  return (
                                                    <MenuItem
                                                      key={
                                                        "listeadversaire-" +
                                                        index
                                                      }
                                                      value={
                                                        adversaire.id_adversaire
                                                      }
                                                    >
                                                      {!adversaire.nom &&
                                                      !adversaire.prenom
                                                        ? "-----"
                                                        : ""}
                                                      {adversaire.nom
                                                        ? adversaire.nom + " "
                                                        : "" + adversaire.prenom
                                                        ? adversaire.prenom
                                                        : ""}
                                                    </MenuItem>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid container item xs={4}>
                                      <Grid item xs={10}>
                                        <FormControl
                                          className={
                                            !displayVehiculeAdversaire[index]
                                              ? styles.backgroudGrey
                                              : styles.backgroudGrey2
                                          }
                                          fullWidth
                                        >
                                          <InputLabel htmlFor="outlined-adornment-password">
                                            Véhicule
                                          </InputLabel>
                                          <OutlinedInput
                                            type="text"
                                            disabled
                                            fullWidth
                                            value={
                                              displayVehiculeAdversaire[index]
                                            }
                                            endAdornment={
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={() =>
                                                    setOpenVehiculeAdversaire(
                                                      (prevState) => {
                                                        const newState = [
                                                          ...prevState,
                                                        ];
                                                        newState[index] = true;
                                                        return newState;
                                                      }
                                                    )
                                                  }
                                                  edge="end"
                                                >
                                                  <SearchIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            }
                                            label="Vehicule"
                                          />
                                        </FormControl>
                                        {openVehiculeAdversaire[index] && (
                                          <VehiculeModal
                                            open={openVehiculeAdversaire[index]}
                                            handleClose={() =>
                                              setOpenVehiculeAdversaire(
                                                (prevState) => {
                                                  const newState = [
                                                    ...prevState,
                                                  ];
                                                  newState[index] = false;
                                                  return newState;
                                                }
                                              )
                                            }
                                            handleChangeValue={(value) => {
                                              let currentAc =
                                                values.accidentcirculation;
                                              let currentAdversaireVehicule =
                                                currentAc?.vehiculeadversaires;
                                              let currentAdversaire;
                                              if (
                                                currentAdversaireVehicule &&
                                                currentAc
                                              ) {
                                                currentAdversaire =
                                                  currentAdversaireVehicule[
                                                    index
                                                  ];
                                                currentAdversaire.vehicule_id =
                                                  value;
                                                currentAdversaireVehicule[
                                                  index
                                                ] = currentAdversaire;
                                                currentAc.vehiculeadversaires =
                                                  currentAdversaireVehicule;
                                              }
                                              setFieldValue(
                                                "accidentcirculation",
                                                currentAc
                                              );
                                            }}
                                            handleChangeDisplay={(value) =>
                                              setDisplayVehiculeAdversaire(
                                                (prevState) => {
                                                  const newState = [
                                                    ...prevState,
                                                  ];
                                                  newState[index] = value;
                                                  return newState;
                                                }
                                              )
                                            }
                                          />
                                        )}
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Button
                                          className={styles.AddButtonModal}
                                          onClick={() => {
                                            setAdversaireVehiculeIndex(index);
                                            setOpenCreateVehiculeAdversaire(
                                              true
                                            );
                                          }}
                                        >
                                          +
                                        </Button>
                                        {openCreateVehiculeAdversaire && (
                                          <VehiculeCreateModal
                                            open={openCreateVehiculeAdversaire}
                                            handleClose={() =>
                                              setOpenCreateVehiculeAdversaire(
                                                false
                                              )
                                            }
                                            handleChangeValue={(value) => {
                                              let currentAc =
                                                values.accidentcirculation;
                                              let currentAdversaireVehicule =
                                                currentAc?.vehiculeadversaires;
                                              let currentAdversaire;
                                              if (
                                                currentAdversaireVehicule &&
                                                currentAc
                                              ) {
                                                currentAdversaire =
                                                  currentAdversaireVehicule[
                                                    index
                                                  ];
                                                currentAdversaire.vehicule_id =
                                                  value;
                                                currentAdversaireVehicule[
                                                  index
                                                ] = currentAdversaire;
                                                currentAc.vehiculeadversaires =
                                                  currentAdversaireVehicule;
                                              }
                                              setFieldValue(
                                                "accidentcirculation",
                                                currentAc
                                              );
                                            }}
                                            handleChangeDisplay={(value) => {
                                              setDisplayVehiculeAdversaire(
                                                (prevState) => {
                                                  const newState = [
                                                    ...prevState,
                                                  ];
                                                  newState[
                                                    adversaireVehiculeIndex
                                                  ] = value;
                                                  return newState;
                                                }
                                              );
                                            }}
                                          />
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <div className={styles.GroupeButton}>
                                        {index === 0 ? (
                                          <span className={styles.ButtonArray}>
                                            <Button
                                              onClick={() =>
                                                handleAddAdversaireVehicule(
                                                  values,
                                                  setFieldValue
                                                )
                                              }
                                            >
                                              <AddIcon />
                                            </Button>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {index !== 0 ? (
                                          <span className={styles.ButtonDelete}>
                                            <Button
                                              onClick={() =>
                                                handleRemoveAdversaireVehicule(
                                                  index,
                                                  values,
                                                  setFieldValue
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </Button>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}
                        </Grid>
                      </Grid>
                    )}
                    {displayGenreAffaire === "Accidents de Travail" && (
                      <Grid item container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            label="Num police Assurance"
                            name="accidenttravail.numpoliceassurance"
                            onChange={handleChange}
                            value={values.accidenttravail?.numpoliceassurance}
                            fullWidth
                            error={
                              !!getIn(
                                errors,
                                "accidenttravail.numpoliceassurance"
                              )
                            }
                            helperText={
                              getIn(
                                errors,
                                "accidenttravail.numpoliceassurance"
                              )
                                ? getIn(
                                    errors,
                                    "accidenttravail.numpoliceassurance"
                                  )
                                : ""
                            }
                          />
                        </Grid>
                        <Grid container item xs={4}>
                          <Grid item xs={10}>
                            <FormControl
                              className={
                                !displayTypeAccidentTravail
                                  ? styles.backgroudGrey
                                  : styles.backgroudGrey2
                              }
                              fullWidth
                            >
                              <InputLabel htmlFor="outlined-adornment-password">
                                Type Accident Travail
                              </InputLabel>
                              <OutlinedInput
                                type="text"
                                disabled
                                fullWidth
                                value={displayTypeAccidentTravail}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setOpenTypeAccidentTravail(true)
                                      }
                                      edge="end"
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Type Accident Travail"
                              />
                            </FormControl>
                            {openTypeAccidentTravail && (
                              <TypeAccidentTravailModal
                                open={openTypeAccidentTravail}
                                handleClose={() =>
                                  setOpenTypeAccidentTravail(false)
                                }
                                handleChangeValue={(value) => {
                                  const currentAccidentTravail =
                                    values.accidenttravail;
                                  if (currentAccidentTravail)
                                    currentAccidentTravail.typeaccidenttravail_id =
                                      value;

                                  setFieldValue(
                                    "accidenttravail",
                                    currentAccidentTravail
                                  );
                                }}
                                handleChangeDisplay={
                                  setDisplayTypeAccidentTravail
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              className={styles.AddButtonModal}
                              onClick={() =>
                                setOpenCreateTypeAccidentTravail(true)
                              }
                            >
                              +
                            </Button>
                            {openCreateTypeAccidentTravail && (
                              <TypeAccidentTravailCreateModal
                                open={openCreateTypeAccidentTravail}
                                handleClose={() =>
                                  setOpenCreateTypeAccidentTravail(false)
                                }
                                handleChangeValue={(value) => {
                                  const currentAccidentTravail =
                                    values.accidenttravail;
                                  if (
                                    currentAccidentTravail?.typeaccidenttravail_id
                                  )
                                    currentAccidentTravail.typeaccidenttravail_id =
                                      value;

                                  setFieldValue(
                                    "accidenttravail",
                                    currentAccidentTravail
                                  );
                                }}
                                handleChangeDisplay={(value) =>
                                  setDisplayTypeAccidentTravail(value)
                                }
                              />
                            )}
                          </Grid>
                          <span className={styles.errorHandle}>
                            {getIn(
                              errors,
                              "accidenttravail.typeaccidenttravail_id"
                            )
                              ? getIn(
                                  errors,
                                  "accidenttravail.typeaccidenttravail_id"
                                )
                              : ""}
                          </span>
                        </Grid>
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              onChange={(value) => {
                                const date = moment(value).format("YYYY-MM-DD");
                                const currentAccidentTravail =
                                  values.accidenttravail;
                                if (currentAccidentTravail)
                                  currentAccidentTravail.dateaccident = date;

                                setFieldValue(
                                  "accidenttravail",
                                  currentAccidentTravail
                                );
                              }}
                              value={moment(
                                values.accidenttravail?.dateaccident
                              )}
                              label="Date Accident"
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: !!getIn(
                                    errors,
                                    "accidenttravail.dateaccident"
                                  ),
                                },
                              }}
                            />
                          </LocalizationProvider>
                          {getIn(errors, "accidenttravail.dateaccident") && (
                            <span className={styles.errorHandle}>
                              {getIn(errors, "accidenttravail.dateaccident")}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Observation"
                            name="accidenttravail.observation"
                            onChange={handleChange}
                            value={values.accidenttravail?.observation}
                            fullWidth
                            error={
                              !!getIn(errors, "accidenttravail.observation")
                            }
                            helperText={
                              getIn(errors, "accidenttravail.observation")
                                ? getIn(errors, "accidenttravail.observation")
                                : ""
                            }
                            multiline
                            rows={3}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} className={styles.ButtonGrid}>
                      <Button
                        className={styles.Annuler}
                        variant="contained"
                        onClick={() => setOpenEdit(false)}
                      >
                        Annuler
                      </Button>
                      <Button
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <Spinner />
          )}
        </>
      ) : !loading && Object.keys(affaire).length > 0 ? (
        <>
          <div className={styles.ReturnList2}>
            <Link to="/affaires" relative="path">
              <ArrowBackIosIcon /> Liste Affaires
            </Link>
            <Button onClick={CreateProcedure}>Créer Une Procédure</Button>
          </div>
          <AffaireDetail affaire={affaire} setEditAffaire={handleSetOpenEdit} />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}
